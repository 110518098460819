export const contactTypes = [
  'email',
  'phone',
  'website',
  'linkedin',
  'twitter',
  'facebook',
  'calendar',
] as const;

export type ContactType = (typeof contactTypes)[number];

export interface Contact {
  label?: string;
  order: number;
  value: string;
}

export interface ContactWithType extends Contact {
  type: ContactType;
}

export interface ContactCardAttrs {
  company: string;
  description: string;
  isDescriptionBlockVisible: boolean;
  isPhotoBlockVisible: boolean;
  jobTitle: string;
  name: string;
  photoUrl: string;
  email?: Contact;
  phone?: Contact;
  website?: Contact;
  linkedin?: Contact;
  twitter?: Contact;
  facebook?: Contact;
  calendar?: Contact;
}
