import { FC, useCallback, useContext } from 'react';
import { useTiptapEditor } from '../../../../../entities/tiptap-editor';
import { useDispatch } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { Button } from '../../../../../shared/ui';
import context from './context';

const Footer: FC = () => {
  const { form } = useContext(context);
  const { editor } = useTiptapEditor();
  const dispatch = useDispatch();

  const onSave = useCallback(() => {
    editor
      ?.chain()
      .focus()
      .updateAttributes('contactCard', form.getValues())
      .run();
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  }, [dispatch, editor, form]);

  const onClose = useCallback(() => {
    form.reset();
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  }, [dispatch, form]);

  return (
    <div className="bg-base-white p-4 flex flex-row justify-between">
      <Button
        variant="text"
        color="secondary"
        size="sm"
        title="Close"
        onClick={onClose}
      >
        Close
      </Button>
      <Button
        variant="text"
        color="primary"
        size="sm"
        title="Close"
        onClick={onSave}
      >
        Save
      </Button>
    </div>
  );
};

export default Footer;
