import { Editor } from '@tiptap/react';
import { useTiptapEditor } from '../../../../../entities/tiptap-editor';
import { FC, useMemo } from 'react';
import { ContactCardItemNodeType } from '../../../../../entities/tiptap-editor/extensions/ContactCard/ContactCard.renderer';
import { ContactCardAttrs } from '@distribute/shared/generate-html';

import Header from './Header';
import { useForm } from 'react-hook-form';
import Footer from './Footer';
import UserInformation from './UserInformation';
import Context from './context';
import Description from './Description';
import Contacts from './Contacts';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateSchema } from './validationSchema';

function getContactCardNode(
  editor: Editor | null
): ContactCardItemNodeType | null {
  if (!editor) {
    throw new Error('No editor found');
  }

  const { from, to } = editor.state.selection;
  let node = null;

  editor.state.doc.nodesBetween(from, to, (n) => {
    if (n.type.name === 'contactCard') {
      node = n;
    }
  });

  return node;
}

const ContactCardEditor: FC = () => {
  const { editor } = useTiptapEditor();
  const node = useMemo(() => getContactCardNode(editor), [editor]);
  const form = useForm<ContactCardAttrs>({
    defaultValues: { ...node?.attrs },
    resolver: yupResolver(validateSchema),
  });

  if (!node) {
    return null;
  }

  return (
    <Context.Provider value={{ form }}>
      <div className="h-full overflow-none flex flex-col justify-between">
        <Header />
        <div className="p-6 flex flex-col gap-y-6 flex-1 overflow-scroll">
          <UserInformation />
          <Description />
          <Contacts />
        </div>
        <Footer />
      </div>
    </Context.Provider>
  );
};

export default ContactCardEditor;
