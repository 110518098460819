import { FC, useContext, useMemo } from 'react';
import context from './context';
import {
  ContactType,
  contactTypes,
  ContactWithType,
} from '@distribute/shared/generate-html';
import { Icon, Select } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import ContactInput, { contactConfigTypeConfig } from './ContactInput';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

const Contacts: FC = () => {
  const { form } = useContext(context);

  const state = form.watch();

  const contactsWithType = useMemo(() => {
    return contactTypes
      .map((type) =>
        state[type] ? ({ ...state[type], type } as ContactWithType) : null
      )
      .filter(Boolean)
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)) as ContactWithType[];
  }, [state]);

  const onAddEmptyContact = (contactType: string) => {
    form.setValue(contactType as ContactType, {
      order: contactsWithType.length,
      value: '',
    });
  };

  const missingContacts = contactTypes.filter((type) => !state[type]);

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }

    const [removed] = contactsWithType.splice(result.source.index, 1);
    contactsWithType.splice(result.destination.index, 0, removed);

    contactsWithType.forEach((contactWithType, index) => {
      form.setValue(contactWithType.type, {
        ...contactWithType,
        order: index,
      });
    });
  };

  return (
    <div className="flex flex-col gap-y-4 pb-6">
      <span className="font-semibold">Contacts</span>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="contacts">
          {(provided) => (
            <div
              className="flex flex-col gap-y-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {contactsWithType.map((contactWithType) => (
                <ContactInput
                  {...contactWithType}
                  isDragDisabled={contactsWithType.length === 1}
                  key={contactWithType.type}
                />
              ))}
              {!!missingContacts.length && (
                <Select
                  className="w-min"
                  items={missingContacts.map((contact) => ({
                    id: contact,
                    title: contactConfigTypeConfig[contact].title,
                    iconName: contactConfigTypeConfig[contact].icon,
                  }))}
                  label="Add Contact"
                  onChange={onAddEmptyContact}
                  hideLabel={true}
                  hideArrow={true}
                  currentValue={{
                    id: 'add-contact',
                    title: (
                      <span className="text-sm flex flex-row flex-nowrap gap-x-1">
                        <Icon glyph={IconMap.Plus} width={20} /> Add contact
                        item
                      </span>
                    ),
                  }}
                  value=" "
                />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Contacts;
