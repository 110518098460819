import { ContactType } from '@distribute/shared/generate-html';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';

const email = Yup.string()
  .required('This is required field.')
  .test('includes-at', function (value) {
    if (!value || value.includes('@')) return true;

    return this.createError({
      message: `Please include an '@' in the email address. '${value}' is missing an '@'.`,
    });
  })
  .test('after-at', function (value) {
    if (!value || (value.includes('@') && value.split('@')[1])) return true;

    return this.createError({
      message: `Please include a part after '@' in the email address. '${value}' is incomplete.`,
    });
  });

const phone = Yup.string().required('This is required field.');

const website = Yup.string().required('This is required field.').url();

const linkedin = Yup.string().required('This is required field.').url();

const twitter = Yup.string().required('This is required field.').url();

const facebook = Yup.string().required('This is required field.').url();

const calendar = Yup.string().required('This is required field.').url();

export const shape: Record<
  ContactType,
  RequiredStringSchema<string | undefined, AnyObject>
> = {
  email,
  phone,
  website,
  linkedin,
  twitter,
  facebook,
  calendar,
};

export const validateSchema = Yup.object().shape(shape);
